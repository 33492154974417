@import "src/scss/module";

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 1047;
  background-color: $primary;
  transform: translateX(550px);
  transition: .4s;

  &[class*=visible] {
    transform: translateX(490px);
  }

  &[class*=show] {
    transform: translateX(0);
  }

  & > div:nth-of-type(1) {
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      transform: rotate(-90deg);
      color: $white !important;
      text-decoration: none;
      white-space: nowrap;

      svg {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
      }
    }
  }

  & > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    width: 490px;
    height: 100%;

    form {
      background-color: rgba(52,60,61,0.1);

      label {
        color: $white;
        font-weight: $font-weight-semibold;
        font-size: 16px;
      }

      input, select {
        border-radius: 0;
        border: 0;
        padding: 15px 20px;
        font-size: 18px;
        font-weight: $font-weight-medium;
      }

      button {
        background-color: $white;
        color: $primary;

        svg {
          fill: $primary
        }

        &:hover {
          background-color: $secondary;
          color: $white;

          svg {
            fill: $white
          }
        }
      }
    }
  }

  &__sectors {
    color: $white;

    [role=button] {
      background-color: rgba(52,60,61,0.1);
      padding: 5px 10px;

      &:hover {
        background-color: $secondary;
      }
    }
  }
}
