@import "src/scss/module";

.menu {

  a {
    color: #727272;
    padding: 0;
    margin-bottom: .25em;
    font-size: 16px;
    white-space: nowrap;

    &:active,
    &:focus,
    &:hover {
      color: $primary;
    }
  }
}