@import "src/scss/module";

.menu {
  flex-direction: row;


  &__navItem {
    position: relative;
    white-space: nowrap;
    display: flex;
  }

  &__navItem &__navLink {
    color: $dark;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    height: 100%;
    line-height: 1.1;
  }

  &__navItem:hover &__navLink,
  &__navItem:active &__navLink,
  &__navItem:focus &__navLink {
    color: $white;
    background-color: $primary !important;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-top: 1px solid $white;
    background-color: $primary;
    min-width: 100%;
  }

  &__dropdownLink {
    color: $white;
    font-weight: 400;
    font-size: 16px;
  }

  &__dropdownChild {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    border-left: 1px solid $white;
    background-color: $primary;
  }

  &__dropdownChildLink {
    color: $white;
    font-weight: 400;
    font-size: 16px;
  }
}