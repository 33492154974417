@import "src/scss/module";

.footer {

  &__logo {
    svg {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  &__detail {
    display: flex;
    justify-content: center;
    font-size: 15px;
    opacity: .75;
    text-align: left;

    svg {
      fill: $black;
    }

    &, dt, dd {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    dd {
      flex-grow: 1;
    }
  }

  &__copyright {
    font-size: $font-size-base;
    opacity: .75;
  }

  &__register {
    white-space: nowrap;
    background-color: #F7F4FB;
  }

  &__siteby {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: inherit;
    font-size: 15px;
    opacity: .75;
    white-space: nowrap;
  }
}