@import "src/scss/module";

.menu {
  padding-top: 100px;
  color: $white;

  [class*=nav-item] {

    a {
      &:hover {
        color: $primary
      }
    }

    & > a {
      font-weight: bold;
      font-size: 20px;
    }

    [class*=nav-item] > a {
      font-weight: normal;
      font-size: 15px;
      padding: .25em 0;
    }
  }
}