@import "src/scss/module";

.header__bg {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(255,255,255,0.8);
  z-index: 1044;
  height: 90px;

  @include media-breakpoint-up(md) {
    height: 100px;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 60px;
  }
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1046;

  @include media-breakpoint-up(xl) {
    padding-right: 60px;
  }

  button[class*=navbar-toggler] {
    color: $white;
    background-color: $primary;
    position: relative;
    z-index: 1047;
    width: 90px;
    height: 90px;
    margin-right: 0 !important;
    border-radius: 0;
    transition: background-color .3s;
    outline: none !important;
    box-shadow: none !important;

    @include media-breakpoint-up(md) {
      width: 100px;
      height: 100px;
    }

    span {
      width: 35px;
      height: 4px;
      display: block;
      background-color: $white;
      margin: auto;
      transition: .3s;

      & + span {
        margin-top: .3em;
      }
    }

    &:not([class*=collapsed]) {
      background-color: darken($primary, 5%);

      span {
        transform: rotate(-45deg);

        & + span {
          margin-top: -4px;
          transform: rotate(45deg);
        }
      }
    }

    [class=navbar-toggler-icon] {
      background-image: url("data:image/svg+xml,<svg width='36' height='15' viewBox='0 0 36 15' fill='white' xmlns='http://www.w3.org/2000/svg'><rect x='0.5' y='0.055542' width='35' height='3.88889'/><rect x='0.5' y='10.9445' width='35' height='3.88889'/></svg>");
    }
  }

  [class*=navbar-brand] {
    height: 90px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1046;
    flex-basis: 300px;

    @include media-breakpoint-up(md) {
      height: 100px;
    }

    a {
      position: relative;
    }

    svg {
      width: 100%;
      height: 100%;
      transition: fill .3s;
      max-width: 100%;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: $primary;
      transition: width .3s;
    }

    &[class*=show] {

      svg {
        fill: $white;
      }

      &::before {
        width: 100%;
      }
    }
  }
}
