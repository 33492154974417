@import "module";
@import "variables.overrides";

@font-face {
  font-family: "DIN Pro";
  src: url("fonts/DINPro-Bold.woff2") format("woff2"), url("fonts/DINPro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DIN Pro";
  src: url("fonts/DINPro-Bold.woff2") format("woff2"), url("fonts/DINPro-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DIN Pro";
  src: url("fonts/DINPro-Medium.woff2") format("woff2"), url("fonts/DINPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DIN Pro";
  src: url("fonts/DINPro-Regular.woff2") format("woff2"), url("fonts/DINPro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

:root {
  --bs-font-sans-serif: "DIN Pro", sans-serif;
  --bs-border-color: #b4adad;
  --bs-body-font-size: 18px;
  --bs-body-line-height: 1.5;
  --bs-body-color: #2a2a2a;
  --bs-body-font-weight: 500;
}

html[lang="en"] {
  .visually-hidden-en {
    display: none;
  }

  .visually-hidden-nl {
    display: initial;
  }
}

html[lang="nl"] {
  .visually-hidden-nl {
    display: none;
  }

  .visually-hidden-en {
    display: initial;
  }

  [data-specialisms="true"] {
    .js-filter-title-button {
      font-size: 0 !important;

      &:before {
        font-size: 20px !important;
        content: 'Specialismen'
      }
    }
  }

  [data-sector-technology="true"] {
    .js-filter-value-name {
      font-size: 0 !important;

      &:before {
        font-size: 14px !important;
        content: 'Technologie'
      }
    }
  }

  [ref="fileDrop"] {
    font-size: 0 !important;
    i {
      font-size: initial;
    }

    [ref="fileBrowse"] {
      &::before {
        font-size: 0.875rem !important;
        content: "Drop bestanden om bij te voegen, of blader";
        margin-left: 0.25em;
      }
    }
  }

  [data-sourceflow-form="form"] {

    .alert.alert-danger {
      p {
        font-size: 0 !important;

        &:before {
          font-size: 20px !important;
          content: 'Los de volgende fouten op voordat u deze verzendt.'
        }
      }
    }
  }
}

body {
  scroll-padding-top: 100px;
}

* {
  font-family: "DIN Pro", sans-serif;
  letter-spacing: 0.01em !important;
}

p {
  overflow-wrap: anywhere;
}

#__next {
  overflow-x: hidden;
  display: flex;
  min-height: 100vh;
  width: 100%;
  padding-top: 90px;

  @include media-breakpoint-up(md) {
    padding-top: 100px;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 60px;
  }
}

.sr-only {
  display: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: $font-weight-bold;
  overflow-wrap: anywhere;

  a {
    color: $primary;
  }
}

h2 {
  font-weight: $font-weight-bold;
}

h5,
.h5 {
  font-weight: 900;
}

.small {

  &, & * {
    letter-spacing: -0.02em !important;
  }
}

.w {
  &-md-20 {
    @include media-breakpoint-up(md) {
      width: 20% !important;
    }
  }
}

.vw {
  &-xs-100 {
    @include media-breakpoint-down(sm) {
      width: 100vw !important;
      max-width: none !important;
      transform: translateX(-50%) !important;
      margin-left: 50% !important;
    }
  }
  &-sm-100 {
    @include media-breakpoint-down(md) {
      width: 100vw !important;
      max-width: none !important;
      transform: translateX(-50%) !important;
      margin-left: 50% !important;
    }
  }
  &-md-100 {
    @include media-breakpoint-down(lg) {
      width: 100vw !important;
      max-width: none !important;
      transform: translateX(-50%) !important;
      margin-left: 50% !important;
    }
  }
}

.h {
  &-md-100 {
    @include media-breakpoint-up(md) {
      height: 100% !important;
    }
  }

  &-lg-100 {
    @include media-breakpoint-up(lg) {
      height: 100% !important;
    }
  }
}

.mw {
  &-sm {
    max-width: map-get($container-max-widths, "sm");
  }

  &-md {
    max-width: map-get($container-max-widths, "md");
  }

  &-lg {
    max-width: map-get($container-max-widths, "lg");
  }

  &-xl {
    max-width: map-get($container-max-widths, "xl");
  }

  &-xxl {
    max-width: map-get($container-max-widths, "xxl");
  }
}

ul {
  li {
    &::marker {
      color: $primary;
    }
  }
}


p,
ul {
  a {
    color: $primary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

details {
  summary {
    color: $primary;
  }
}

.text {
  &-white {
    a {
      color: $white;
      text-decoration: underline;
    }
  }
  // &-primary {
  //   ol, ul {
  //     li {
  //       &::marker {
  //         color: $white;
  //       }
  //     }
  //   }
  // }
}

ol,
ul {
  padding-left: 1rem;

  li {
    a {
      color: $primary;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.bg-primary {
  ul {
    li {
      &::marker {
        color: $white;
      }
    }
  }
}

.accordion {
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-active-bg: transparent;
  --bs-accordion-btn-focus-box-shadow: none;

  &-button {
    &[aria-expanded="true"] {
      margin-bottom: -1px;
      box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    }
  }
}

.list-group {
  border: 0;
  background-color: transparent;

  &-item {
    border: 0;
    border-bottom: 1px solid #d3cdde;
    border-radius: 0 !important;
    background-color: transparent;

    a {
      text-decoration: none;
      color: #161616;
      display: flex;
      justify-content: space-between;

      &:hover {
        color: $primary;
      }

      svg {
        width: 18px;
      }
    }
  }
}

.btn {
  --bs-btn-border-radius: 4px;
  --bs-btn-padding-y: 0.75em;
  --bs-btn-padding-x: 1em;
  --bs-btn-font-weight: var(--font-weight-medium);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: .3s;

  &:before {
    transition: .3s;
  }

  &-primary {
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: var(--bs-secondary);
    --bs-btn-hover-border-color: var(--bs-secondary);
    --bs-btn-disabled-color: var(--bs-white);
    --bs-btn-active-color: var(--bs-white);
    --bs-btn-active-bg: var(--bs-secondary);
    --bs-btn-active-border-color: var(--bs-secondary);;
    --bs-btn-border-width: 2px;
    text-transform: capitalize;

    svg {
      fill: $white;
      transition: 0.15s ease-in-out;
    }

    &:active,
    &[class*="active"] {
      svg {
        fill: $primary;
      }
    }
  }

  &-tertiary {
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-bg: var(#{--bs-secondary});
    --bs-btn-hover-border-color: var(#{--bs-secondary});
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-disabled-color: var(--bs-white);
    --bs-btn-active-color: var(--bs-tertiary);
    --bs-btn-active-bg: transparent;
    --bs-btn-border-width: 2px;
    text-transform: capitalize;

    svg {
      fill: $white;
    }

    &-inverse {
      --bs-btn-bg: var(#{--bs-secondary});
      --bs-btn-border-color: var(#{--bs-secondary});
      --bs-btn-hover-bg: var(#{--bs-tertiary});
      --bs-btn-hover-border-color: var(#{--bs-tertiary});
      --bs-btn-color: var(--bs-white);
      --bs-btn-hover-color: var(--bs-white);
      --bs-btn-disabled-color: var(--bs-white);
      --bs-btn-active-color: var(--bs-tertiary);
      --bs-btn-active-bg: transparent;
      --bs-btn-border-width: 2px;
      text-transform: capitalize;

      svg {
        fill: $white;
      }
    }
  }

  &-quinary {
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: var(#{--bs-senary});
    --bs-btn-hover-border-color: var(#{--bs-senary});
    --bs-btn-disabled-color: var(--bs-white);
    --bs-btn-active-color: var(--bs-quinary);
    --bs-btn-active-bg: transparent;
    --bs-btn-border-width: 2px;
    text-transform: capitalize;

    svg {
      fill: $white;
      transition: 0.15s ease-in-out;
    }

    &:active,
    &[class*="active"] {
      svg {
        fill: $quinary;
      }
    }
  }

  &[class*="btn-outline-"] {
    display: flex;
    font-size: 18px;
    font-weight: $font-weight-semibold;
    background-color: transparent !important;
    border: 0;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    text-align: left !important;
    gap: .5em;

    @include media-breakpoint-up(sm) {
      gap: .75em;
    }

    @include media-breakpoint-up(md) {
      gap: 1em;
    }

    &:before {
      content: "";
      display: block;
      border-radius: 100px;
      width: 35px;
      height: 35px;
      background-position: center;
      background-repeat: no-repeat;
      transition: $transition-base;
      flex-shrink: 0;
    }

    &.btn-lg {
      &:before {
        width: 44px;
        height: 44px;
      }
    }
  }

  &.btn-outline-light {
    text-decoration: none;

    &:before {
      background-color: $light;
      background-image: url("/assets/arrow_right_primary.svg");
    }

    &:hover {
      color: $primary;
      &:before {
        background-color: $primary;
        background-image: url("/assets/arrow_right_light.svg");
      }
    }

    &.arrow-secondary {
      text-decoration: none;

      &:before {
        background-color: $light;
        background-image: url("/assets/arrow_right_secondary.svg");
      }

      &:hover {
        color: $primary;
        &:before {
          background-color: $primary;
          background-image: url("/assets/arrow_right_light.svg");
        }
      }
    }

    &.arrow-quaternary {
      text-decoration: none;

      &:before {
        background-color: $light;
        background-image: url("/assets/arrow_right_quaternary.svg");
      }

      &:hover {
        color: $secondary;
        &:before {
          background-color: $secondary;
          background-image: url("/assets/arrow_right_light.svg");
        }
      }
    }
  }

  &.btn-outline-primary {
    color: $primary;

    &:before {
      background-color: $primary;
      background-image: url("/assets/arrow_right_light.svg");
    }

    &:hover {
      color: $secondary;
      &:before {
        background-color: $secondary;
      }
    }
  }

  &.btn-outline-secondary {
    color: $secondary;

    &:before {
      background-color: $secondary;
      background-image: url("/assets/arrow_right_light.svg");
    }

    &:hover {
      color: $primary;
      &:before {
        background-color: $primary;
      }
    }
  }

  &.btn-outline-quaternary {
    color: $quaternary;

    &:before {
      background-color: $quaternary;
      background-image: url("/assets/arrow_right_light.svg");
    }

    &:hover {
      color: $secondary;
      &:before {
        background-color: $secondary;
      }
    }
  }

  &.btn-outline-quinary {
    color: $secondary;

    &:before {
      background-color: $quinary;
      background-image: url("/assets/arrow_right_light.svg");
    }

    &:hover {
      color: $secondary !important;
      &:before {
        background-color: $secondary;
      }
    }
  }

  &-secondary {
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-disabled-color: var(--bs-white);
    --bs-btn-active-color: var(--bs-secondary);
    --bs-btn-active-bg: transparent;
    --bs-btn-border-width: 2px;
    text-transform: capitalize;
    text-decoration: none !important;

    svg {
      fill: $white;
      transition: 0.15s ease-in-out;
    }

    &:active,
    &[class*="active"] {
      svg {
        fill: $primary;
      }
    }
    &:hover {
      background-color: var(--bs-primary);
      border-color: var(--bs-primary);
    }
  }
}

.bg-secondary {
  .text-muted, .help-block {
    color: rgba($white, .9) !important;
  }
}

.bg-white-80 {
  background-color: rgba($white, 0.8);
  backdrop-filter: blur(5px);
}

.bg-white-50 {
  background-color: rgba($white, 0.5);
  backdrop-filter: blur(5px);
}

.rotate-90 {
  display: flex;
  transform: rotate(90deg);
}

.rotate-180 {
  display: flex;
  transform: rotate(180deg);
}

.rotate-270 {
  display: flex;
  transform: rotate(270deg) translateY(-100%);
}

.offcanvas {
  --bs-offcanvas-width: 640px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 50px 0;
  background-color: rgba(52, 60, 61, 0.4);
  backdrop-filter: blur(30px);
  opacity: 0;
  transition: transform 0.5s, opacity 0.5s;

  &.showing,
  &.show:not(.hiding) {
    transform: none;
    opacity: 1;
  }

  &-backdrop {
    display: none !important;
  }
}

a.text-secondary {
  &:hover {
    color: $primary !important;
  }
}

.text-overlay {
  position: relative;

  &__content {
    position: relative;
    z-index: 2;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
  }
}

.border-md-end {
  @include media-breakpoint-up(md) {
    border-right: 1px solid;
  }
}

.freeprivacypolicy-com---palette-light.freeprivacypolicy-com---nb {
  opacity: 0.9;
  left: 0;
  max-width: initial;
  background-color: $white !important;

  @include media-breakpoint-up(xl) {
    right: 60px;
  }
}

.freeprivacypolicy-com---nb .cc-nb-main-container {
  padding: 1.5rem !important;
}

.cc-nb-reject,
.cc-nb-okagree {
  background-color: $primary !important;
}
